<template>
  <section class="reg" :style="`min-height:${clientHeight}px`">
    <div class="logo">
      <div class="fl"><img src="../../../assets/images/h5-logo-2x.png" /></div>
      <div class="fr" @click="changeRouterRule()">活动规则</div>
    </div>
    <div class="text">
      <img
        src="../../../assets/images/title-2x.png"
        alt=""
        class="title_image"
      />
      <img
        src="../../../assets/images/font-little-title-2x.png"
        class="font-tite_image"
        alt=""
      />
    </div>
    <div class="reg-form">
      <div>
        <input
          type="tel"
          v-model="mobile"
          maxlength="11"
          placeholder="请输入手机号"
        />
      </div>
      <div class="reg-code">
        <input type="text" v-model="code" placeholder="请输入验证码" />
        <button class="get-code" :disabled="codeBtnStatus" @click="getCode()">
          {{ codeText }}
        </button>
      </div>
      <div class="error-tips">
        <div v-if="errorInfo">
          <img src="../../../assets/images/icon-jingshix-2x.png" alt="" />
          <span>
            {{ errorInfo }}
          </span>
        </div>
      </div>
      <button
        class="reg-btn"
        :disabled="subBtnStatus"
        @click="changeRouterSuccess()"
      >
        立即领取
      </button>
    </div>
  </section>
</template>

<script>
import { Toast } from "vant";
import { register, getSendSms } from "@/api/reg";
import { isMobile } from "@/utils/validate";


export default {
  data() {
    return {
      count: 60,
      codeText: "获取验证",
      codeBtnStatus: false,
      subBtnStatus: true,
      errorInfo: "",
      mobile: "",
      code: "",
      adcode: "",
      clientHeight: document.documentElement.clientHeight,
    };
  },
  components: {},
  watch: {
    mobile: function (newVal) {
      if (newVal) {
        this.errorInfo = "";
      }
      if (newVal && this.code) {
        this.subBtnStatus = false;
      } else {
        this.subBtnStatus = true;
      }
    },
    code: function (newVal) {
      if (newVal) {
        this.errorInfo = "";
      }
      if (newVal && this.mobile) {
        this.subBtnStatus = false;
      } else {
        this.subBtnStatus = true;
      }
    },
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
    this.getAdCode();
  },
  methods: {
    getCode() {
      this.errorInfo = "";

      if (!this.mobile) {
        this.errorInfo = "请输入手机号";
        return;
      }

      if (!isMobile(this.mobile)) {
        this.errorInfo = "请输入您正确的手机号";
        return;
      }
      let regActUuid = this.$route.query.regActUuid;
      this.codeBtnStatus = true;
      Toast.loading({
        duration: 0,
        message: "获取中...",
      });

      getSendSms({
        appid: "c543eae4aa0a49fdab3ed761f6345001",
        regActUuid: regActUuid,
        phone: this.mobile,
      }).then(
        (res) => {
          Toast.clear();
          if (res.returnCode === 10000) {
            this.cutdown();
          } else {
            this.codeBtnStatus = false;
            Toast(res.msg);
          }
        },
        () => {
          this.codeBtnStatus = false;
          Toast.clear();
        }
      );
    },
    getAdCode() {
      var geolocation,
        that = this;
      geolocation = new AMap.Geolocation();
      geolocation.getCurrentPosition();
      AMap.event.addListener(
        geolocation,
        "complete",
        function onComplete(data) {
          that.adcode = data.addressComponent.adcode;
        }
      ); //返回定位信息
    },
    cutdown() {
      let cutdownTimeout = setTimeout(() => {
        this.count--;
        if (this.count >= 0) {
          this.codeText = `重新获取(${this.count}S)`;
          this.cutdown();
        } else {
          clearTimeout(cutdownTimeout);
          this.codeText = `获取验证码`;
          this.codeBtnStatus = false;
          this.count = 60;
        }
      }, 1000);
    },
    changeRouterRule() {
      this.$router.push({
        path: "/rule",
        query: {
          id: this.$route.query.regActUuid,
        },
      });
    },
    changeRouterSuccess() {
      let regActUuid = this.$route.query.regActUuid;
      if (!this.mobile) {
        this.errorInfo = "请输入手机号";
        return;
      }
      if (!this.code) {
        this.errorInfo = "请输入验证码";
        return;
      }

      register({
        adcode: this.adcode,
        appid: "c543eae4aa0a49fdab3ed761f6345001",
        phone: this.mobile,
        code: this.code,
        regActUuid: regActUuid,
      }).then((res) => {
        if (res.returnCode === 10000) {
          window.location.href =
            "/reg-success?pUuid=" + res.data + "&regActUuid=" + regActUuid;
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.reg {
  position: relative;
  width: 100%;
  height: 100%;
  background: #1f1f21 url("../../../assets/images/h5-bg-2x.png");
  background-size: cover;

  .logo {
    padding: 20px 24px;

    overflow: hidden;
    img {
      height: 30px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }
  }

  .text {
    text-align: center;

    .title_image {
      width: 52%;
      margin-bottom: 26px;
    }
    .font-tite_image {
      width: 100%;
    }
  }
  .reg-form {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    div {
      margin-bottom: 24px;
      input {
        padding: 0 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        width: 315px;
        height: 56px;
        background: #ffffff;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
      }
      &.reg-code {
        position: relative;
        width: 315px;
        margin: 0 auto 0px;
        .get-code {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 15px;
          font-weight: 400;
          color: #222;
          border: none;
          width: 118px;
          background: #eaeaea;
          height: 56px;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
          opacity: 1;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .error-tips {
      margin: 5px auto 5px;
      width: 315px;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #8e6d5b;
      height: 20px;
      line-height: 20px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: middle;
      }
      span {
        line-height: 18px;
      }
    }
    button.reg-btn {
      margin: 0 auto 0px;
      width: 315px;
      font-size: 16px;

      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffec00;
      line-height: 22px;
      padding: 13px 0 15px 0;
      background: url("../../../assets/images/h5-btn-2x.png") no-repeat;
      background-size: cover;
      border: none;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
input::-webkit-input-placeholder {
  /* WebKit browsers*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  opacity: 0.5;
}
input:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}
input::-moz-input-placeholder {
  /* Mozilla Firefox 19+*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}
</style>