import request from '@/global/xhr'
// import qs from 'qs'

export function register(data){
  return request({
    url: '/api/h5/activity/register',
    method: 'post',
    params: data
  })
}

export function getCoupon(data){
  return request({
    url: '/api/h5/activity/registerSuccess',
    method: 'post',
    params: data
  })
}

export function getSendSms(data){
  return request({
    url: '/api/h5/activity/sendSms',
    method: 'post',
    params: data
  })
}

export function sendDownload(data){
  return request({
    url: '/api/h5/activity/download',
    method: 'post',
    params: data
  })
}

export function getRule(data) {
  return request({
    url: '/api/h5/activity/registerInfo',
    method: 'get',
    params: data
  })
}

